export const AppointmentStatus = {
    OPEN: 'OPEN',
    REOPEN: 'REOPEN',
    CLOSE: 'CLOSE',
}

export const ConsultationsTypes = {
    CONSULTA_INICIAL: 1,
    TESTES_COGNITIVOS: 2,
    JOGO_TESTE: 3,
    APLICACAO_JOGO: 4,
    CONSULTA_REGULAR: 5,
    CONSULTA_FINAL: 6,
    NENHUMA_APLICACAO: 7,

}


export const Games = {
    KNAPDOWN: 1,
    JOGO_TESTE: 3,
}