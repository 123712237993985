
// heat chart data generator
function generateDataHeatMap(count, yrange) {
  let i = 0
  const series = []
  while (i < count) {
    const x = `w${(i + 1).toString()}`
    const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

    series.push({
      x,
      y,
    })
    // eslint-disable-next-line no-plusplus
    i++
  }
  return series
}

// colors
const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  

  chartTijolosDestruidos: {
    series: [100],
    chartOptions: {
      colors: [chartColors.donut.series1],
      plotOptions: {
        radialBar: {
          size: 185,
          hollow: {
            size: '40%',
          },
          track: {
            margin: 10,
          },
          dataLabels: {
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
              show: false,
            },
            value: {
              fontSize: '25px',
              fontFamily: 'Montserrat',
              position: 'center',
              offsetX: 0,
              offsetY: 10,
              
            },
            total: {
              show: false,
              fontSize: '1rem',
            },
          },
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        lineCap: 'round',
      },
     
    },
  },

  chartModificadoresPositivos: {
    series: [80],
    chartOptions: {
      colors: [chartColors.donut.series2],
      plotOptions: {
        radialBar: {
          size: 185,
          hollow: {
            size: '40%',
          },
          track: {
            margin: 10,
          },
          dataLabels: {
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
              show: false,
            },
            value: {
              fontSize: '25px',
              fontFamily: 'Montserrat',
              position: 'center',
              offsetX: 0,
              offsetY: 10,
              
            },
            total: {
              show: false,
              fontSize: '1rem',
              
              formatter() {
                return '80%'
              },
            },
          },
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        lineCap: 'round',
      },
     
    },
  },

  chartModificadoresNegativos: {
    series: [80],
    chartOptions: {
      colors: [chartColors.donut.series3],
      plotOptions: {
        radialBar: {
          size: 185,
          hollow: {
            size: '40%',
          },
          track: {
            margin: 10,
          },
          dataLabels: {
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
              show: false,
            },
            value: {
              fontSize: '25px',
              fontFamily: 'Montserrat',
              position: 'center',
              offsetX: 0,
              offsetY: 10,
              
            },
            total: {
              show: false,
              fontSize: '1rem',
              
              formatter() {
                return '80%'
              },
            },
          },
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        lineCap: 'round',
      },
     
    },
  },

  chartDesistencias: {
    series: [80],
    chartOptions: {
      colors: [chartColors.donut.series4],
      plotOptions: {
        radialBar: {
          size: 185,
          hollow: {
            size: '40%',
          },
          track: {
            margin: 10,
          },
          dataLabels: {
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
              show: false,
            },
            value: {
              fontSize: '25px',
              fontFamily: 'Montserrat',
              position: 'center',
              offsetX: 0,
              offsetY: 10,
              
            },
            total: {
              show: false,
              fontSize: '1rem',
              
              formatter() {
                return '80%'
              },
            },
          },
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        lineCap: 'round',
      },
     
    },
  },


}
