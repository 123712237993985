<template>
  <div v-if="selectedAppointment">
    <b-row>
      <b-col>
        <b-card class="bg-gradient-primary">
          <b-row align-v="center">
            <b-col>
              <h1 class="titulo-header-page">
                <feather-icon icon="ListIcon" size="30" class="mr-50" />
                Resultados do Paciente
              </h1>
              <!-- <h4 class="text-white">Inicie uma Consulta Inicial:</h4> -->
            </b-col>

            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-right ml-1 botao-white-outline"
              @click="endAppointment()"
            >
              <feather-icon icon="DownloadIcon" class="mr-50" />
              <span class="align-middle">Salvar em PDF</span>
            </b-button> -->

            <!-- <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-secondary"
        class="button-right ml-1 "
      >
        <feather-icon icon="Trash2Icon" class="mr-50" />
        <span class="align-middle">Excluir</span>
      </b-button> -->
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!--Card topo -->

    <!-- Geral -->
    <b-row>
      <b-col lg="4" class="d-flex align-items-stretch">
        <b-card class="w-100 bluecard-x">
          <b-row class="align-items-center h-100">
            <b-col>
              <b-row class="justify-content-center">
                <b-dropdown
                  v-if="selectedAppointment"
                  :text="getDateFormated(selectedAppointment.startDate)"
                  size="sm"
                  class="budget-dropdown"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="appointment in appointments"
                    :key="appointment.id"
                    @click="onSelectAppointment(appointment)"
                  >
                    {{ getDateFormated(appointment.startDate) }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-row>
              <b-row class="justify-content-center mt-4">
                <h1 class="mb-25 fonte-result-data text-center">
                  <feather-icon icon="CalendarIcon" size="40" />
                  {{ getTitleDateAppointment(selectedAppointment.startDate) }}
                </h1>
              </b-row>
              <b-row class="justify-content-center mt-4">
                <div class="d-flex justify-content-center">
                  <div class="d-flex align-items-center">
                    <b-avatar variant="light-secondary" rounded>
                      <feather-icon icon="ClockIcon" size="18" />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ getExtenseDate(selectedAppointment.startDate) }} às
                        {{ getExtenseDate(selectedAppointment.endDate) }}
                      </h5>
                      <small>Duração da Consulta</small>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="8" class="d-flex align-items-stretch">
        <b-card class="w-100">
          <b-row>
            <b-col>
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <!-- <b-avatar
            size="70px"
            variant="light-danger"
            text="CB"
        /> -->
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0 titulo-paciente-result">
                      {{ selectedAppointment.patient.physicalPerson.firstName }}
                      {{ selectedAppointment.patient.physicalPerson.lastName }}
                    </h4>
                    <span class="card-text">
                      {{
                        selectedAppointment.patient.physicalPerson.person
                          .email || 'N/A'
                      }}</span
                    >
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-button
                      variant="primary"
                      :to="'/paciente/' + selectedAppointment.patient.id"
                    >
                      Visualizar Prontuário
                    </b-button>
                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-primary" rounded>
                    <feather-icon icon="UserCheckIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{
                        selectedAppointment.user.firstName +
                        ' ' +
                        selectedAppointment.user.lastName
                      }}
                    </h5>
                    <small>Profissional</small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar variant="light-success" rounded>
                    <feather-icon icon="MapPinIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ selectedAppointment.location }}</h5>
                    <small>Localização</small>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-stretch w-100">
        <b-card class="w-100">
          <h3>Devolutiva da Consulta</h3>

          <div v-if="!devolutivaInEdit">
            <p class="mb-0">
              {{ selectedAppointment.comments }}
            </p>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mt-2"
              @click="editDevolutiva()"
            >
              Editar
            </b-button>
          </div>
          <div v-else>
            <b-form-textarea
              id="textarea-default"
              v-model="devolutivaInEdit"
              placeholder="Inserir Detalhes"
              rows="8"
            />
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mt-2 mr-1"
              @click="devolutivaInEdit = ''"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="mt-2"
            >
              Salvar
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <div v-if="game && analytic">
      <!-- Resultado Partida -->
      <h2>
        <feather-icon icon="BarChart2Icon" size="25" class="mr-30" /> Resultados
        da Partida
      </h2>
      <b-card>
        <b-row>
          <b-col lg="3" md="6" sm="6">
            <statistic-card-horizontal
              icon="ClockIcon"
              :statistic="getTimeOfGame()"
              statistic-title="Tempo da partida"
              class="card-grey"
            />
          </b-col>
          <b-col lg="3" md="6" sm="6">
            <statistic-card-horizontal
              icon="CheckCircleIcon"
              color="success"
              :statistic="getHitsOfGame()"
              statistic-title="Acertos"
              class="card-grey"
            />
          </b-col>
          <b-col lg="3" md="6" sm="6">
            <statistic-card-horizontal
              icon="XCircleIcon"
              color="warning"
              :statistic="getErrorsOfGame()"
              statistic-title="Erros"
              class="card-grey"
            />
          </b-col>
          <b-col lg="3" md="6" sm="6">
            <statistic-card-horizontal
              icon="PauseCircleIcon"
              color="black"
              :statistic="getPausesOfGame()"
              statistic-title="Pausas"
              class="card-grey"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="4" md="6">
            <b-card no-body class="card-grey">
              <b-row>
                <b-col lg="7">
                  <div class="p-2">
                    <h3>Tijolos Destruídos</h3>
                    <span
                      >Total:
                      <strong>{{ this.analytic.brickDestroyed }}</strong></span
                    >
                    <span
                      >Destruídos:
                      <strong>{{ this.analytic.bricksTotal }}</strong></span
                    >
                  </div>
                </b-col>

                <b-col lg="5">
                  <vue-apex-charts
                    type="radialBar"
                    height="200"
                    :options="apexChatData.chartTijolosDestruidos.chartOptions"
                    :series="getPercentBricksDestroyeds()"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col lg="4" md="6">
            <b-card no-body class="card-grey">
              <b-row>
                <b-col lg="7">
                  <div class="p-2">
                    <h3>Modificadores Positivos</h3>
                    <span>Total: <strong>{{ getTotalIncreasesOfGame() }}</strong></span>
                    <span>Utilizados: <strong>{{ getIncreasesOfGame() }}</strong></span>
                  </div>
                </b-col>

                <b-col lg="5">
                  <vue-apex-charts
                    type="radialBar"
                    height="200"
                    :options="
                      apexChatData.chartModificadoresPositivos.chartOptions
                    "
                    :series="getPercentIncrease()"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col lg="4" md="6">
            <b-card no-body class="card-grey">
              <b-row>
                <b-col lg="7">
                  <div class="p-2">
                    <h3>Modificadores Negativos</h3>
                    <span>Total: <strong>{{ getTotalDecreasesOfGame() }}</strong></span>
                    <span>Utilizados: <strong>{{ getDecreasesOfGame() }}</strong></span>
                  </div>
                </b-col>

                <b-col lg="5">
                  <vue-apex-charts
                    type="radialBar"
                    height="200"
                    :options="
                      apexChatData.chartModificadoresNegativos.chartOptions
                    "
                    :series="getPercentDecrease()"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- <b-col lg="3" md="6">
            <b-card no-body class="card-grey">
              <statistic-card-horizontal
                icon="XOctagonIcon"
                color="danger"
                statistic="02"
                statistic-title="Desistências"
              />
            </b-card>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-card class="card-transaction card-grey" no-body>
              <b-card-header>
                <b-card-title>Configurações da Partida</b-card-title>
                <feather-icon size="18" class="cursor-pointer" />
              </b-card-header>

              <b-card-body>
                <div
                  v-for="transaction in transactionData"
                  :key="transaction.mode"
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        :variant="transaction.avatarVariant"
                      >
                        <feather-icon size="18" :icon="transaction.avatar" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        {{ transaction.mode }}
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div
                    class="font-weight-bolder"
                    :class="
                      transaction.deduction ? 'text-danger' : 'text-success'
                    "
                  >
                    {{ game.levelSettings[transaction.attribute] }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="4">
            <b-card class="card-transaction card-grey" no-body>
              <b-card-header>
                <b-card-title>Modificadores</b-card-title>
                <feather-icon size="18" class="cursor-pointer" />
              </b-card-header>

              <b-card-body>
                <div
                  v-for="modificador in ModificadoresData"
                  :key="modificador.mode"
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <feather-icon
                        size="18"
                        :icon="
                          getStatusModifier(modificador) == 'Ativo'
                            ? 'CheckSquareIcon'
                            : 'SquareIcon'
                        "
                      />
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        {{ modificador.nome }}
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div
                    class="font-weight-bolder"
                    :class="
                      modificador.deduction ? 'text-danger' : 'text-success'
                    "
                  >
                    {{ getStatusModifier(modificador) }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col lg="4">
            <b-card class="card-transaction card-grey" no-body>
              <b-card-header>
                <b-card-title>Fase</b-card-title>
              </b-card-header>
              <b-card-body>
                <div class="img-screenshot">
                  <img
                    src="assets/images/screenshots/breakout_screenshot.png"
                  />
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <!-- Resultado Teste Cognitivo -->
    <div v-if="selectedAppointment.appCognitiveTests">
      <h2>
        <feather-icon icon="ActivityIcon" size="25" class="mr-30" /> Teste
        Cognitivo
      </h2>

      <b-card>
        <b-row>
          <b-col lg="6">
            <!-- about -->
            <div>
              <h5 class="text-capitalize mt-3 mb-75">Testes realizados</h5>
              <b-card-text>
                {{selectedAppointment.appCognitiveTests.type}}
              </b-card-text>
              <h5 class="text-capitalize mt-3 mb-75">Duração da Passação</h5>
              <b-card-text> {{selectedAppointment.appCognitiveTests.hour}}:{{selectedAppointment.appCognitiveTests.minute}} horas</b-card-text>
            </div>
          </b-col>
          <b-col lg="6">
            <h3 >Resultados Obtidos</h3>
            <p>
              {{selectedAppointment.appCognitiveTests.testResult}}
            </p>
            <!-- <p class="mb-3">
              <b-img
                :src="require('@/assets/images/icons/jpg.png')"
                height="auto"
                width="30"
                class="mr-1"
              />
              <span class="align-bottom">Teste Sr. Jonatan.jpg</span>
            </p> -->
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              Editar
            </b-button> -->
          </b-col>
        </b-row>
      </b-card>
    </div>
    <!-- Resultado Jogo Teste -->
    <h2>
      <feather-icon icon="PieChartIcon" size="25" class="mr-30" /> Jogo Teste
    </h2>
    <b-card>
      <b-row>
        <b-col lg="3" md="6" sm="6">
          <statistic-card-horizontal
            icon="ClockIcon"
            statistic="1:53"
            statistic-title="Tempo da partida"
            class="card-grey"
          />
        </b-col>
        <b-col lg="3" md="6" sm="6">
          <statistic-card-horizontal
            icon="CheckCircleIcon"
            color="success"
            statistic="43"
            statistic-title="Acertos"
            class="card-grey"
          />
        </b-col>
        <b-col lg="3" md="6" sm="6">
          <statistic-card-horizontal
            icon="XCircleIcon"
            color="warning"
            statistic="08"
            statistic-title="Erros"
            class="card-grey"
          />
        </b-col>
        <b-col lg="3" md="6" sm="6">
          <statistic-card-horizontal
            icon="PauseCircleIcon"
            color="black"
            statistic="02"
            statistic-title="Pausas"
            class="card-grey"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BMediaBody,
  BMedia,
  BMediaAside,
  BImg,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'
import gameAnalyticsEventService from '@/services/gameAnalyticsEventService'
import medicalAppointmentService from '@/services/medicalAppointmentService'
import * as moment from 'moment'
import { hideSpinner, showSpinner } from '@/services/spinService'
import gameLevelService from '@/services/gameLevelService'
import { ConsultationsTypes, Games } from '@/constants/status'
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    StatisticCardHorizontal,
    BCardTitle,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BMediaBody,
    BMedia,
    BMediaAside,
    BImg,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
  },
  props: ['idConsulta'],
  data() {
    return {
      game: null,
      games: null,
      analytic: null,
      gameTest: null,
      devolutivaInEdit: '',
      consulta: {},
      selectedAppointment: null,
      apexChatData,
      appointments: [],
      transactionData: [
        // {
        //   mode: 'Formatação',
        //   types: 'Starbucks',
        //   avatar: 'HexagonIcon',
        //   avatarVariant: 'light-secondary',
        //   payment: 'Linha',
        //   deduction: false,
        // },
        // {
        //   mode: 'Áudio',
        //   types: 'Add Money',
        //   avatar: 'Volume2Icon',
        //   avatarVariant: 'light-secondary',
        //   payment: 'Ativado',
        //   deduction: false,
        // },
        {
          mode: 'Tamanho da bola',
          types: 'Add Money',
          avatar: 'CircleIcon',
          avatarVariant: 'light-secondary',
          attribute: 'ballSpeed',
        },
        {
          mode: 'Velocidade da Bola',
          types: 'Ordered Food',
          avatar: 'ChevronsRightIcon',
          avatarVariant: 'light-secondary',
          attribute: 'ballSize',
        },
        // {
        //   mode: 'Cor da Bola',
        //   types: 'Refund',
        //   avatar: 'TargetIcon',
        //   avatarVariant: 'light-secondary',
        //   payment: 'Saturada',
        //   deduction: false,
        // },
      ],

      ModificadoresData: [
        {
          tipo: 'Positivo',
          attribute: 'ballSize',
          nome: 'Aumento de Raquete',
        },
        {
          tipo: 'Positivo',
          attribute: 'ballSpeed',
          nome: 'Requete Grudenta',
        },
        {
          tipo: 'Neutro',
          attribute: 'modifierMultipleBallsQuantity',
          nome: 'Múltiplas Bolas',
        },
        {
          tipo: 'Neutro',
          attribute: 'modifierPaddleDecreaseQuantity',
          nome: 'Diminuição da Raquete',
        },
        {
          tipo: 'Neutro',
          attribute: 'modifierPaddleFrozenQuantity',
          nome: 'Raquete Congelada',
        },
        {
          tipo: 'Neutro',
          attribute: 'modifierPaddleMagneticQuantity',
          nome: 'Raquete Magnética',
        },
      ],
    }
  },
  methods: {
    getTimeOfGame() {
      return moment(new Date())
        .startOf('day')
        .seconds(this.analytic?.duration || 0)
        .format('mm:ss')
    },

    getHitsOfGame() {
      return (this.analytic.brickBallHit || 0).toString()
    },

    getErrorsOfGame() {
      return (this.analytic.ballLost || 0).toString()
    },
    getPausesOfGame() {
      return (this.analytic.pauses || 0).toString()
    },

    getPercentBricksDestroyeds(){
      return [(this.analytic?.brickDestroyed / this.analytic?.bricksTotal) * 100]
    },

    getPercentIncrease(){
      return [(this.getIncreasesOfGame()/this.getTotalIncreasesOfGame()) * 100]
    },
    getTotalIncreasesOfGame(){
      return 2 // modifierPaddleIncreaseQuantity and modifierPaddleMagneticQuantity
    },
    getIncreasesOfGame(){
      const increases = 0
      if(this.game?.modifierPaddleIncreaseQuantity) increases++
      if(this.game?.modifierPaddleMagneticQuantity) increases++
      return increases
    },


    getPercentDecrease(){
      return [(this.getDecreasesOfGame()/this.getTotalDecreasesOfGame()) * 100]
    },
    getTotalDecreasesOfGame(){
      return 2 // modifierPaddleDecreaseQuantity and modifierPaddleFrozenQuantity
    },
    getDecreasesOfGame(){
      const decreases = 0
      if(this.game?.modifierPaddleDecreaseQuantity) decreases++
      if(this.game?.modifierPaddleFrozenQuantity) decreases++
      return decreases
    },

    getStatusModifier(modifier) {
      if (!this.game) return 'Inativo'
      return this.game.levelSettings[modifier.status] ? 'Ativo' : 'Inativo'
    },
    onSelectAppointment(appointment) {
      this.selectedAppointment = appointment
      this.getGames()
    },
    editDevolutiva() {
      this.devolutivaInEdit = this.selectedAppointment.comments
    },
    getDateFormated(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    getExtenseDate(date) {
      return moment(date).format('HH:mm[h]')
    },
    getTitleDateAppointment(date) {
      return moment(date).format('DD MMM').toUpperCase()
    },
    async getAppointments() {
      await medicalAppointmentService
        .getById(this.idConsulta)
        .then(async resConsulta => {
          const consulta = resConsulta
          await medicalAppointmentService
            .getByPatient(consulta.patient.id)
            .then(async resAppointments => {
              this.appointments = resAppointments.filter(
                appointment => appointment.status === 'CLOSE'
              )
              this.selectedAppointment = this.appointments.find(
                appointment => appointment.id == this.idConsulta
              )
              this.getGames()
            })
        })
    },
    getAnalytic(gameLevelId) {
      return gameAnalyticsEventService.getByGameLevel(gameLevelId)
    },
    getGames() {
      gameLevelService
        .getGameLevelByGameAndAppointment(
          Games.KNAPDOWN,
          this.selectedAppointment.id
        )
        .then(async res => {
          this.games = res
          this.game = res?.length ? this.games[0] : null
          if(this.game){
            this.analytic = await this.getAnalytic(this.game.id)
          }
        })
      gameLevelService
        .getGameLevelByGameAndAppointment(
          Games.JOGO_TESTE,
          this.selectedAppointment.id
        )
        .then(res => {
          this.gameTest = res.length ? res[0] : null
        })
    },
  },
  mounted() {
    this.getAppointments()
  },
}
</script>
